<!-- /*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/ -->
<a routerLink="/events/fbspl-joining-as-silver-corporate-partner-at-accelerate-2025">
  <div
    class="py-2 text-center text-black mb-1"
    style="background: var(--orange)"
  >
    <p class="mb-0 fs-26">
      <b>Meet FBSPL at Accelerate 2025, Powered by NetVu </b>
    </p>
  </div>
</a>
<section class="main-banner-bg">
  <img appCdnUrl="assets/Icon/squarevector.svg" class="white-square" />
  <div class="container">
    <div class="row align-items-center py-md-5">
      <div class="col-lg-6">
        <h1 class="text-white font-48-30">
          Next-gen outsourcing <br />for businesses globally
        </h1>
        <p class="py-2 font-24-16 fw-normal">
          Now get access to Business Process Outsourcing (BPO) solutions proven
          to bring growth. Our industry veterans don’t just optimize workflows —
          they help you reinvent them. Through human touch, smart automation,
          and real transformation, let’s break new ground and rise together.
        </p>
        <a
          routerLink="book-free-consultation-call"
          class="anchorCTA mt-3 d-inline-block"
        >
          <h6
            class="font-36-24 text-white clickable-heading d-flex align-items-center"
          >
            Talk to us
            <div class="headingCTA ms-2">
              <button class="blueCTA podcast-card-cta whiteCTA"></button>
            </div>
          </h6>
        </a>
        <!-- <SubmitBtn
          text="Talk to us"
          link="/book-free-consultation-call"
        ></SubmitBtn> -->
      </div>
      <div class="col-lg-6 text-center">
        <div id="animationInsurance" style="height: 450px"></div>
      </div>
    </div>
  </div>
  <img appCdnUrl="assets/Icon/right-arrows.svg" class="arrows" />
</section>

<!-- Floating Statistics Section -->
<section class="container floating-stats-section">
  <div class="container bg-white box-shadow bordered py-4">
    <div class="row text-center px-3 px-md-0">
      <div class="col-md-3 stat-box my-3 my-lg-0 border-end">
        <span class="text-primary fw-bolder display-5">{{ counters[0] }}%</span>
        <p class="h4 fw-bolder mb-0">Reduction in resource overhead</p>
      </div>
      <div class="col-md-3 stat-box my-3 my-lg-0 border-end">
        <span class="text-primary fw-bolder display-5">{{ counters[1] }}%</span>
        <p class="h4 fw-bolder mb-0">Less turnaround time</p>
      </div>
      <div class="col-md-3 stat-box my-3 my-lg-0 border-end">
        <span class="text-primary fw-bolder display-5">{{ counters[2] }}%</span>
        <p class="h4 fw-bolder mb-0">Increase in overall productivity</p>
      </div>
      <div class="col-md-3 stat-box my-3 my-lg-0 border-end">
        <span class="text-primary fw-bolder display-5">{{ counters[3] }}%</span>
        <p class="h4 fw-bolder mb-0">Better customer satisfaction</p>
      </div>
    </div>
  </div>
</section>

<!-- About Section -->
<section class="about-section bg-secondary-30">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 my-4 my-lg-0">
        <app-vimeo-player
          [thumbnail]="'assets/newImages/Video Cover.jpg'"
          [videoUrl]="'https://player.vimeo.com/video/961113555'"
          [useWhitePlayButton]="true"
        >
        </app-vimeo-player>
      </div>
      <div class="col-lg-6 my-4 my-lg-0">
        <h2 class="text-primary font-48-30">About FBSPL</h2>
        <p class="font-24-16">
          Empowering businesses through innovative outsourcing solutions since
          2006. As a leader in Business Process Management and Consulting, we
          specialize in Insurance Outsourcing, Accounting & Bookkeeping, Data
          Annotation, Business Intelligence, and Digital Marketing Services,
          enabling companies to have complete control over their operations and
          drive sustainable growth.
        </p>
        <app-Infographic></app-Infographic>
        <SubmitBtn text="Learn more" link="/about-who-we-are"></SubmitBtn>
      </div>
    </div>
  </div>
  <img appCdnUrl="assets/Icon/triangle-blue1.svg" class="triangle-blue" />
</section>

<section class="container py-4">
  <h2 class="text-primary font-48-30 mb-2" data-aos="fade-up">Our services</h2>
  <div class="">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
        <a routerLink="/services/insurance-bpo-outsourcing">
          <div class="card bordered serviceCards Insurance">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Insurance Outsourcing
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              Enhance operations, from policy processing and claims management
              to new business setup, with our specialized insurance BPO
              services. <br />
              <span class="text-primary cursor-pointer">Read more...</span>
            </p>
          </div>
        </a>
      </div>
      <div class="col-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
        <a routerLink="/services/accounting-bookkeeping-services">
          <div class="card bordered serviceCards Account-bookkeeping">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Accounting & Bookkeeping
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              Accounting services that don’t just keep your business compliant
              but keep you growing. Built for modern businesses like yours.
              <br />
              <span class="text-primary cursor-pointer">Read more...</span>
            </p>
          </div>
        </a>
      </div>
      <div class="col-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
        <a routerLink="/services/data-annotation-services">
          <div class="card bordered serviceCards Annotation">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Data Annotation
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              From data labeling and tagging to synthetic data generation, train
              your AI/ML models with accurate, reliable data annotation support.
              <br />
              <span class="text-primary cursor-pointer">Read more...</span>
            </p>
          </div>
        </a>
      </div>
      <div class="col-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
        <a routerLink="/services/business-intelligence-services">
          <div class="card bordered serviceCards Visualization">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Business Intelligence
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              Your data holds the answers to your biggest questions! Are you
              making the most of it? With our business intelligence services,
              gain a holistic view of your operations
              <br />
              <span class="text-primary cursor-pointer">Read more...</span>
            </p>
          </div>
        </a>
      </div>
      <div class="col-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
        <a routerLink="/services/outsource-digital-marketing-services">
          <div class="card bordered serviceCards Marketing">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Digital Marketing
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              Shining out in today’s digital space requires smart and strategic
              digital marketing solutions. Whether you are trying to boost your
              visibility or gain traffic
              <br />
              <span class="text-primary cursor-pointer">Read more...</span>
            </p>
          </div>
        </a>
      </div>
      <!-- <div class="col-12 col-md-6 col-lg-4 py-3" data-aos="zoom-in">
        <a routerLink="/services/ai-outsourcing-and-consulting-services">
          <div class="card bordered serviceCards AI">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Artificial Intelligence
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              Say goodbye to mundane tasks and hello to efficiency with our
              artificial intelligence services for insurance and other
              businesses. Whether you need advanced
              <br />
              <span class="text-primary cursor-pointer">Read more...</span>
            </p>
          </div>
        </a>
      </div> -->
    </div>
  </div>
</section>
<app-ourAssets></app-ourAssets>

<app-know-best
  [cards]="[
    {
      description:
        'FBSPL transformed our operations quickly. Their exceptional administrative support, bookkeeping, and recruiting services significantly improved our processes and boosted lead conversion rates. Their integrity, professionalism, and tailored approach set them apart, making them valuable partners in our growth. ',
      name: 'Francesca Furlanetto',
      designation: 'Director Owner'
    },
    {
      description:
        'As the Director of Accounting for an E&S Insurance Company, I connected with FBSPL to support our monthly manual processes. Their team has been a game-changer, enabling us to meet deadlines and improve workflows. The team quickly adapted and delivered high-quality work with over 95% accuracy. Their dedication and service-oriented approach have been invaluable to our growing company. ',
      name: 'Monica Spivey',
      designation: 'Directory of Accounting'
    }
  ]"
  [videos]="[
    {
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 10 Francesca Furlanetto.jpg',
      alt: 'Listen to our client '
    },
    {
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 09 Monica Spivey.jpg',
      alt: 'Our Success Stories '
    }
  ]"
></app-know-best>

<ThinkingSection></ThinkingSection>
<app-Slider></app-Slider>
